<template>
  <div></div>
</template>

<script>
import UA from '@/modules/UserAgent';

export default {
  name: 'BrazePageScrolled',
  props: {
    projectId: {
      type: String,
      required: true,
    },
    projectCode: {
      type: String,
      required: true,
    },
    projectName: {
      type: String,
      required: true,
    },
    imageUrl: {
      type: String,
      required: true,
    },
    isComingSoon: {
      type: Boolean,
      required: true,
    },
    isFinished: {
      type: Boolean,
      required: true,
    },
    tagIds: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      ua: null,
    };
  },
  async created() {
    const ua = new UA();
    await ua.init();
    this.ua = ua;
  },
  mounted() {
    window.addEventListener('scroll', this.onScrolled);
  },
  methods: {
    onScrolled() {
      // 画面の50%のスクロール量でイベントを発火する
      const scrolledValue = window.pageYOffset;
      let threshold;
      if (this.ua.isPC()) {
        const bodyHeight = document.body.offsetHeight;
        const footer = document.querySelector('.footer');
        const footerHeight = footer ? footer.offsetHeight : 0;
        threshold = (bodyHeight - footerHeight) * 0.5;
      } else {
        const wrapper = document.getElementById('wrapper');
        const offsetHeight = wrapper
          ? wrapper.offsetHeight
          : document.body.offsetHeight;
        threshold = offsetHeight * 0.5;
      }
      if (scrolledValue > threshold) {
        // 一度イベントを発火したらlistenerは不要なので削除する
        window.removeEventListener('scroll', this.onScrolled);

        const { braze } = window;
        braze.logCustomEvent('pagescrolled_project', {
          timestamp: new Date(),
          project_id: this.projectId,
          project_code: this.projectCode,
          project_name: this.projectName,
          image_url: this.imageUrl,
          is_coming_soon: this.isComingSoon,
          is_finished: this.isFinished,
          tag: this.tagIds,
        });
      }
    },
  },
};
</script>
