import { createApp } from 'vue';
import BrazePageScrolled from '@/components/project/BrazePageScrolled';

const el = document.getElementById('vue-braze-page-scrolled');

if (el !== null) {
  const {
    projectId,
    projectCode,
    projectName,
    imageUrl,
    isComingSoon,
    isFinished,
    tagIds,
  } = el.dataset;

  const isComingSoonBool = isComingSoon === '1';
  const isFinishedBool = isFinished === '1';

  let tagIdsArray = [];
  if (tagIds) {
    tagIdsArray = tagIds.split(',');
  }

  const app = createApp(BrazePageScrolled, {
    projectId,
    projectCode,
    projectName,
    imageUrl,
    isComingSoon: isComingSoonBool,
    isFinished: isFinishedBool,
    tagIds: tagIdsArray,
  });
  app.mount('#vue-braze-page-scrolled');
}
